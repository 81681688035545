section.ok{
    background-color: #555;
  }
  .contact-div{
    width: 100%;
    height: 1100px;
    background-color:white;
  }
  .scroll-div{
    width: 100%;
    height: 100%;
    background-color: rgba(5, 29, 48, 0.5);
    position: absolute;
    top: 0%;
    left: 0%;
    opacity: 0.7;
  }
  .contact-div-1{
    width: 85%;
    height: 500px;
    display: flex;
    margin: auto;
  }
  .contact-icons{
    width: 40%;
    height: 500px;
    margin: 30px;
    text-align: center;
  }
  .contact-icons i{
    font-size: 50px; 
    padding-top: 30%;
    color: #221C1C;
  }
  .contact-icons h2{
    padding-top: 25%;
    color:#221C1C;
  }
  section.ok .contact-icons p{
    font-size: 20px;
    padding-top: 3%;
    color: #221C1C;
  }
  section.ok .contact-icons a{
    padding:3% 5% 3% 5%;
    background-color: pink;
    border-radius: 5px;
    text-decoration: none;
    border: 2px solid #221C1C;
    color: #221C1C;
    align-items: center;
  }
  section.ok .contact-icons .a1{
    margin-top: 30px;
  }
  section.ok .contact-icons .a1 i{
      font-size: 20px;
      padding: 0%;
      margin-right:10px;
  }
  .contact-div-2{
    width: 100%;
    height: 600px;
  }
  #form{
    width: 50%;
    height: 580px;
    background-color: white;
    margin: auto;
    text-align: center;
  }
  #form input{
    width: 98%;
    height: 50px;
    margin-top: 30px;
    border: 3px solid #221C1C;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 2%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  }
  #form textarea{
    width: 98%;
    height: 200px;
    margin-top: 30px;
    border: 3px solid #221C1C;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 2%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .form-btn{
    padding: 8% 22% 8% 22%;
    background-color: pink;
    text-decoration: none;
    color:#221C1C;
    font-size: 13px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 5px;
    font-weight: 550;
  }
  .btn-div{
    margin-top: 30px;
    padding-right: 84%;
  }
  
@media(max-width:768px){
  .contact-div{
    width: 100%;
  }
  .contact-div-1{
    width: 100%;
    flex-wrap: wrap;
  }
  .contact-icons{
    width: 100%;
    height: 250px;
    margin: 0;
  }
  .contact-icons i{
    padding-top: 0%;
    font-size: 30px; 
  }
  .contact-icons h2{
    padding-top: 5%;
    font-size: 20px;
  }
  .contact-div-2{
    width: 100%;
    margin-top: 200px;
  }
  #form{
    width: 90%;
  }
  #form input{
    height: 40px;
  }
  #form textarea{
    height: 100px;
  }
  .btn-div{
    margin-top: 10px;
  }
}