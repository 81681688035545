@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800);

html, body
{
height: 100%;
margin: 0;
}
.img
{
position: relative;
height: 100%;
background-image: attr(data-img url); 
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
border-bottom: 3px solid white;
text-align: center;
font-family: 'Open Sans', sans-serif;
font-size: 28px;
font-weight: bold;
letter-spacing: -2px;
color: rgba(255,255,255,0.85);
cursor: default;
}
.img:last-of-type
{
border-bottom: none;
}
.img:before
{
content: '';
display: inline-block;
height: 100%;
vertical-align: middle;
}
.multiline
{
display: inline-block;
color: black;
font-weight: 100;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.multiline-2
{
display: inline-block;
color: white;
font-weight: 100;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.max
{
display: block;
font-size: 60px;
letter-spacing: 1px;
color: black;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.max-2
{
display: block;
font-size: 60px;
letter-spacing: 1px;
color: white;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.img .permalink
{
position: absolute;
right: 0;
top: 0;
width: 16px;
height: 16px;
background: url(https://i.imgur.com/wdQCy4F.png) center no-repeat;
opacity: .2;
transition: opacity .5s;
}
.img .permalink:hover
{
opacity: 1;
}

@media(max-width:768px){
    .img{
        width: 100%;
        height: 40%;
    }
    #image3{
        background-position: right;
    }
    .max{
        font-size: 30px;
    }  
}