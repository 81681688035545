*{
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
}
section{
    height: 780px;
    width: 100%;
    display: table;
  }
  section.ok{
    background-color: #555;
  }
  section img{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  section .con-div{
    color: white;
    font-family: arial;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 40px;
    font-weight: bold;
    z-index: 1000;
    position: relative;
  }
  .scroll-down {
    opacity: 1;
    -webkit-transition: all .5s ease-in 3s;
    transition: all .5s ease-in 3s;
  }
  .scroll-down {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -16px;
    display: block;
    width: 32px;
    height: 32px;
    border: 2px solid #FFF;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 9000;
    -webkit-animation: bounce 2s infinite 2s;
    animation: bounce 2s infinite 2s;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    transform: scale(1)
  }
  .scroll-down:before {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 6px);
      transform: rotate(-45deg);
      display: block;
      width: 12px;
      height: 12px;
      content: "";  
      border: 2px solid white;
      border-width: 0px 0 2px 2px;
  }
.scroll-div{
    width: 100%;
    height: 100%;
    background-color: rgba(5, 29, 48, 0.5);
    position: absolute;
    top: 0%;
    left: 0%;
    opacity: 0.7;
  }

  @media(max-width:768px){
    section{
      width: 100%;
      height: 400px;
    }
    section img{
      height: 350px;
    } 
    .scroll-div{
      width: 100%;
      height: 41.5%!important;  
    }
    section .con-div{
      font-size: 20px;
      padding-bottom: 10%;
    }
    .scroll-down{
      margin-bottom: 490px!important;
    }   
  }

  @keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    60% {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
  
  