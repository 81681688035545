#section02 a {
    padding-top: 60px;
  }
  #section02 a span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
  }
  #section02 a span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 16px;
    height: 16px;
    margin: -12px 0 0 -8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
  }
  .products001{
    width: 100%;
    height: auto;
    background-color:white;
    display: flex;
    position: relative;
  }
  .product-inspiration{
    width:50%;
    height: auto;
  }
  .product-inspiration img{
    width: 35%;
    height: 600px;
    margin-top: 100px;
    margin-left: 180px;
  }
  .product-inspiration h2{
    font-size: 35px;
    color: purple;
    padding-top: 15%;
    padding-left: 2%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
  }
  .product-inspiration p{
    font-size: 16px;
    width: 78%;
    color: #221C1C;
    margin-top: 20px;
    padding-left: 2%;
    line-height: 25px;
    font-family: "Tahoma", Sans-serif;
  }
  .product-inspiration hr{
    margin-top: 20px;
    width: 10%;
    margin-left: 16px;
    font-weight: bolder;
  }
  .product-inspiration span{
    color: #1A1A1A;
  }
  
  @media(max-width:768px){
    .products001{
      width: 100%; 
    }
    .demo{
      width: 100%;
      height: 400px;
    }
    .product-inspiration img{
      margin-left: 10px;
      margin-top: 50px;
      width: 45%;
      height: 250px;
    }
    .product-inspiration{
      width: 100%;
    }
    .product-inspiration h2{
      padding-top: 5%;
      padding-left: 0%;
      font-size: 20px;
    }
    .product-inspiration p{
      width: 100%;
      font-size: 8px;
      line-height: 10px;
      margin-top: 5px;
      padding-left: 0%;
    }
    .product-inspiration hr{
      margin-top: 5px;
      margin-left: 2px;
    }
    
  }