.ingredients{
    width: 80%;
    height: 400px;
    display: flex;
    text-align: center;
    margin: auto;
  }
  .ingredient-1{
    width: 33%;
    height: 300px;
    background-color: rgb(255, 252, 248);
    margin: 6px;
    border-radius: 5px;
    border: 3px solid #221C1C;
  }
  .ingredient-1 h3{
    font-size: 20px;
    padding-right: 65%;
    padding-top: 2%;
    color: #221C1C;
  }
  .ingredient-1 hr{
    width: 35%;
    height: 3px;
    background-color:#1A1A1A;
    margin-left: 15px;
    margin-top: 20px;
  }
  .ingredient-img{
    width: 88%;
    height: 180px;
    display: flex;
    margin: auto;
    margin-top:20px;
  }
  .ingredient-img div img{
    width: 80%;
    height: 100px;
    margin: 10px;
  }
  .ingredient-img p{
    color: #221C1C;
    font-size: 18px;
  }

  @media(max-width:768px){
    .ingredients{
      width: 95%;
      margin-top: 30px;
      flex-wrap: wrap;
      margin: auto;
      height: auto;
    }
    .ingredient-1{
      width: 80%;
      height: 200px;
      margin: 10px auto;
    }
    .ingredient-1 h3{
      font-size: 15px;
      padding-left: 1%;
    }
    .ingredient-1 hr{
      margin-top: 10px;
    }
    .ingredient-img div img{
      width: 80%;
      height: 60px;
      margin: 15px;
    }
    
  }