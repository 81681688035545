.products003{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
  }
  .product-inspiration-3{
    width:50%;
    height: auto;
  }
  .product-inspiration-3{
    width: 50%;
    height: auto;
  }
  .product-inspiration-3 img{
    width: 65%;
    height: 450px;
    margin-left: 180px;
  }
  .product-inspiration-3 h2{
    font-size: 32px;
    color: purple;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding-top: 4%;
    font-weight: bold;
  }
  .product-inspiration-3 p{
    color: #221C1C;
    font-size: 16px;
    line-height: 25px;
    width: 78%;
    margin-top: 10px;
    font-family: "Tahoma", Sans-serif;
  }
  .product-inspiration-3 hr{
    margin-top: 20px;
    width: 10%;
    font-weight: bolder;
  }
  .product-inspiration-3 span{
    color: #1A1A1A;
  }
  
  @media(max-width:768px){
    .products003{
      width: 100%;
    }
    .product-inspiration-3 img{
      margin-left: 10px;
      width: 85%;
      height: 230px;
    }
    .product-inspiration-3 h2{
      font-size: 20px;
      padding-top: 0%;
    }
    .product-inspiration-3 hr{
      margin-top: 5px;   
    }
    .product-inspiration-3 p{
      width: 98%;
      font-size: 8px;
      line-height: 10px;
      margin-top: 10px;
    }
  }