.footer-3{
    width: 100%;
    height: 100px;
    background-color: #221C1C;
    display: flex;
    justify-content: space-around;
    }
    .footer-3 p{ 
    color: white;
    padding-right: 35%;
    padding-top: 2.5%;
    }
    .icons-2{
    padding-top: 2.5%;
    display: flex;
    }
    .icons-2 i{
    margin: 10px;
    color: white;
    }
    .icons-2 i:hover{
    transition:1s;
    color: pink;
    }
    @media(max-width:768px){
        .footer-3{
            width: 100%;
            height: 80px;
        }
        .footer-3 p{
            width: 100%;
            font-size: 15px;
            margin-left: 5%;
        }
    }