.allproducts{
    width: 100%;
    height: 200px;
    border-bottom: 2px solid #221C1C;
}
.allproducts h1{
    color: #221C1C;
    position: relative;
    top: 48%;
    font-size: 40px;
    margin-left: 170px;
    font-weight: 600;
    padding-top: 2%;
}
.shop-div{
    width: 100%;
    height: auto;
}
.shop-div span .fa-plus{
    position: relative;
    left: 2%;
    font-size: 25px;
}
.shopping{
    width: 90%;
    height:auto;
    margin-top: 30px;
    margin-left: 150px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.shopproducts{
    width: 23%;
    height: 420px;
    margin: 25px;
    border: 5px solid #221C1C;
    background-color: rgb(255, 252, 248);
}
.shopproducts img{
    width: 100%;
    height: 280px;
}
.shopproducts h2{
    padding-left: 2%;
    padding-top: 6%;
    font-size: 18px;
    font-family: "Raleway",arial,sans-serif;
    color: #221C1C;
    transition: 0.5s; 
}
.shopproducts p{
    font-family: "Raleway",arial,sans-serif;
    font-size: 14px;
    padding-top: 4%;
    color: #221C1C;
    transition: 0.5s;
    padding-left: 2%;
}
.shopproducts h2:hover{
    color: pink;
    transition: 0.5s;
}
.shopproducts a{
    padding: 3% 10% 3% 10%;
    background-color: #221C1C;
    position: relative;
    top: 5%;
    border-radius: 3px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    font-weight: bolder;
    cursor: pointer;
    margin-left: 7px;
}
.shopproducts a:hover{
    background-color: pink;
    transition: 0.5s;
}
.shop-div h1{
    padding-left: 12%;
    font-size: 40px;
    color: #221C1C;
    padding-top: 5%;
}
.shop-div .shop-p{
    padding-left: 12%;
    font-size: 17px;
}
 .shopbigdiv{
    width: 100%;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.4);
    z-index: 30000;
    position: fixed;
    top: 0%;
    left:0%;
}
.shopsmalldiv{
    width: 50%;
    height: 450px;
    background-color: white;
    margin: auto;
    margin-top: 140px;
    display: flex;
    border: 5px solid #221C1C;
}
.smalldiv001{
    width: 50%;
    height: 400px;
}
.smalldiv001 img{
    width: 95%;
    height: 100%;
    margin-left: 10px;
    margin-top: 10px;
  }
.smalldiv{
    width: 50%;
    height: 400px;
    padding-left: 2%;
    padding-top: 1%;
    position: relative;
}
  .smalldiv p{
    padding-top: 5%;
    color: #221C1C;
    font-size: 25px;
  }
  .smalldiv h1{
    color: rgb(128, 0, 51);
    font-size: 40px;
    }
  .smalldiv .text{
    color: #221C1C;
    font-size: 20px;
  }
  .smalldiv button{
    margin-top: 50px;
    width: 40%;
    height: 50px;
    border-radius: 5px;
    border: hidden;
    background-color: #221C1C;
    font-size: 20px;
    color: white;
  }
  .smalldiv i{
    position: relative;
    z-index: 40000;
    left: 93%;
    top: -2%;
  } 
  .fa-trash{
    margin-left: 10px;
  }
  
  @media(max-width:768px){
    .allproducts{
        width: 100%;
        height: 100px;
    }
    .allproducts h1{
        font-size: 20px;
        margin-left: 40px;
        padding-top: 4%;
    }
    .shop-div{
        width: 100%;
    }
    .shop-div h1{
        font-size: 20px;
    }
    .shopping{
        width: 100%;
        margin-left: 0px;
        flex-wrap: wrap;
    }
    .shopproducts{
        width: 70%;
        margin: 10px auto;
    }
    .shopbigdiv{
        width: 100%;
    }
    .shopsmalldiv{
        width: 90%;
        height: 300px;
    }
    .smalldiv001{
        height: 200px;
        margin-top: 30px;
    }
    .smalldiv{
        height: 300px;
    }
    .smalldiv h1{
        font-size: 20px;
    }
    .smalldiv p{
        font-size: 15px;
    }
    .smalldiv .text{
        font-size: 10px;
    }
    .smalldiv button{
        width: 50%;
        margin-top: 10px;
        height: 30px;
        font-size: 10px;
    }
    .smalldiv i{
        left: 90%;
    }
  }