.eau{
    width: 100%;
    height: 700px;
    display: flex;
  }
  .eau-1{
    width: 50%;
    height: 100%;
    text-align: end;
  }
  .eau-1 img{
    width: 75%;
    height: 100%;
  }
  .eau-2{
    width: 50%;
    height: 100%;
    display: block;
  }
  .eau-3{
    width: 80%;
    height: 200px;
    text-align: center;
  }
  .eau-3 h2{
    width: 70%;
    margin: auto;
    font-size: 50px;
    font-family: 'Dancing Script', cursive;
    line-height: 43px;
    font-weight: bold;
    color: #221C1C;
  }
  .eau-3 p{
    width: 80%;
    margin: auto;
    padding-top: 4%;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 600;
    color: #221C1C;
  }
  .eau-4{
    width: 80%;
    height: 500px;  
  }
  .eau-4 img{
    width: 62%;
    height: 100%;
    margin-bottom: 100px;
    margin-left: 100px;
  }

  @media(max-width:768px){
      .eau{
        width: 100%;
        height: auto;
      }
      .eau-1{
        height: 200px;
        text-align: center;
      }
      .eau-1 img{
        height: 200px;
        margin-top: 15%;
      }
      .eau-3{
        height: auto;
      }
      .eau-3 h2{
        font-size: 15px;
        line-height: 15px;
      }
      .eau-3 p{
        font-size: 10px;
      }
      .eau-4{
        text-align: center;
        height: 200px;
      }
      .eau-4 img{
        width: 90%;
        margin-left: 0%;
        height: 125px;
        /* margin-top: 15%; */
      }
      .eau-2{
        height: auto;
        margin-top: 8%;
      }
     
  }