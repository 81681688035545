@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400);
html{
  scroll-behavior: smooth;
}
section{
  width: 100%;
  height: auto;
}
.demo-section{
  width: 100%;
  height: 700px;
  position: relative;
}
.demo-section::after{
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(5, 29, 48, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.7;
}
.demo-section img{
  width: 100%;
  height: 100%;
}
.demo-section p{
  color: red;
  position: relative;
  text-align: center;
  bottom: 45%;
  font-size: 20px;
  color: white;
}
section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9000;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font : normal 300 64px/1 'Josefin Sans', sans-serif;
  text-align: center;
  white-space: nowrap;
  font-size: 50px;
  font-weight: bold;
}
.demo a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.demo a:hover {
  opacity: .5;
}
#section01 a {
  padding-top: 60px;
}
#section01 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

@media(max-width:768px){
  .demo-section{
    width: 100%;
    height: 350px;
  }
  section h1{
    font-size: 35px;
  }
  
}