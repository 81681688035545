.footer-2{
    width: 100%;
    height: 300px;
    background-color:#eceaea ;
    }
    .footer-2 p{
    padding-left: 10%;
    padding-top: 6%;
    font-family: "Raleway",arial,sans-serif;
    text-rendering: optimizeSpeed;
    font-size: 13px;
    font-style: normal;
    color: #221C1C;
    }
    .footer-2 hr{
    width: 30%;
    margin-left: 10%;
    margin-top: 1%;
    background-color: #221C1C;
    }
    .icons{
    margin-left: 10%;
    margin-top: 1%;
    }
    .icons i{
    margin-left: 1%;
    font-size: 17px;
    }
    .icons a{
    text-decoration: none;
    color:#221C1C ;
    }
    .icons i:hover{
    transition:1s;
    color: pink;
    }
    @media(max-width:768px){
        .footer-2{
            width: 100%;
            height: 100px;
        }
    }