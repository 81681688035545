.imgdiv{
    width: 100%;
    height: 650px;
    text-align: center;
}
.imgdiv iframe{
    width: 100%;
    height: 100%;
}
@media(max-width:768px){
    .imgdiv{
        width: 100%;
        height: 180px;
    }
    .imgdiv iframe{
        width: 100%;
    }
}