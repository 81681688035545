*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }
  nav{
    height: 100px;
    width: 100%;
    background-color: #221C1C;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    position: fixed;
    z-index: 10000;
  }
  nav img{
    width: 8%;
    height: 80px;
  }
  .div-logo ul{
    display: flex;
  }
  .div-logo ul li{
    margin-left: 90px;
  }
  .div-logo{
    height: 60px;
    width: 40%;
    display: flex;
    text-align: center;
    align-items: center;
  }
  .div-logo i{
    margin-left: 30px;
    font-size: 20px;
  }
  .div-logo ul a{
    text-decoration: none;
    font-style: normal;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .href-2{
    color:white;
    transition: 0.5s;
    position: relative;
  }
  .href-2:hover{
    color: pink;
  }
  .href-1{
    color: white;
  }
  .href-1:hover{
    color: pink;  
  }
  @media(max-width:768px){
    nav{
      width: 100%;
      height: auto;
    }
    nav img{
      margin-right: 70px;
      width: 28%;
      height: 50px;
    }
    .div-logo{
      width: 80%;
      margin-right: 20%;
    }
    .div-logo ul{
      width: 80%;
    }
    .div-logo ul li{
      font-size: 5px;
      margin: 4px;
    }
    .div-logo ul a{
      font-size: 15px;
    }
  }