.discover{
    width: 100%;
    height: 780px;
    display: block;
    }
    .discovertext{
    width: 100%;
    height: 200px;
    text-align: center;
    }
    .discovertext h2{
    width: 70%;
    margin: auto;
    font-size: 50px;
    font-family: 'Dancing Script', cursive;
    line-height: 43px;
    font-weight: bold;
    color: #221C1C;
    padding-top: 3%;
    }
    .discovertext p{
    width: 60%;
    margin: auto;
    padding-top: 2%;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #221C1C;
    }
    .discoverphotos{
    width: 77%;
    height: 520px;
    display: flex;
    margin: auto;
    }
    .photo{
    width: 35%;
    height: 510px;
    margin: 10px;
    border: 5px solid #221C1C;
    background-color: rgb(255, 252, 248);
    }
    .photo img{
    width: 100%;
    height: 400px;
    }
    .divbutton{
    width: 100%;
    height: 100px;
    text-align: center;
    padding-top: 10%;
    }
    .button{
    text-decoration: none;
    padding: 5% 11% 5% 11%;
    background-color:#221C1C;
    color: white;
    border-radius: 30px;
    transform: scale(0);
    transition: 0.5s; 
    }
    @media(max-width:768px){
        .discover{
            width: 100%;
            height: auto;
        }
        .discovertext{
            width: 100%;
        }
        .discovertext h2{
            width: 100%;
            font-size: 25px;
        }
        .discovertext p{
            width: 100%;
            font-size: 15px;
        }
        .discoverphotos{
            width: 100%;
            height: auto;
            flex-wrap: wrap;
        }
        .photo{
            width: 80%;
            margin: 7px auto;
        }
        .photo img{
            height: auto;
        }
        .divbutton{
            width: 100%;
            height: 80px;
            padding-top: 10%;
        }
        .button{
            font-size: 15px;
             padding: 5% 25% 5% 25%;
        }
    }