#root{
  height: 100%;
}
.basketsmalldiv{
  width: 100%;
  height: auto; 
  display: flex;
  margin: 20px auto;
  border-bottom: 1px solid #1A1A1A;
  border: 0.1px solid  #898989;
  background-color:#ffffff;
  display: none; 
}
.basketimg{
  width: 90%;
  height: 100px;
  margin: 7px;
  display: flex;
}
.imgsml{
  width: 40%;
}
.basketimg img{
  width: 100%;
  height: 100%;
  border: 3px solid #221C1C;
}
.baskettext{
  width: 60%;
  height: auto;
  line-height: 25px;
}
.baskettext h3{
  font-weight: 200;
  font-size: 20px;
  color: #221C1C;
}
.baskettext p{
  padding-bottom: 5%;
  font-size: 15px;
  font-weight: bold;
  color: #221C1C;
}
.basketsmalldiv i{
   color: #adabab;
}
.basketBig{
 position: absolute;
 top: 40px;
 right: 5%;
 width: 20%;
}
.basketBig:hover .basketsmalldiv{
  display: block;
}
.basketBig i{
  color: white;
}
.basket-p{
  font-size: 20px;
  padding: 3%;
  color: #636363;
}
.basketsmalldiv .empt{
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
  color: #636363;
}
.p-icn{
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.p-icn .fa-xmark{
  color: black;
}
.totalprc{
  width: 100%;
  height: 70px;
  background-color: rgb(255, 252, 248);
  border-top: 1px solid black;
  padding: 5%;
}
.totalprc h1{
  color: #221C1C;
  font-weight: 400;
}
.sbtotal{
  font-size: 20px;
  padding-left: 2%;
  color: #221C1C;
}
.count{
  width: 60%;
  background-color:pink;
  text-align: center;
}
.count p{
  margin-top: -6%;
}
.btn-count{
  width: 60%;
  height: 20px;
  display: flex;
  background-color:#221C1C;
  margin-left: 30px;
  margin-bottom: 3%;
}
.btn-count button{
  width: 20%;
  height: 20px;
}
@media(max-width:768px){
  .basketBig{
    top: 20px;
    width: 35%;
    right: 0%;
  }
  .basketBig i{
    font-size: 13px;
  }
  .basketsmalldiv{
    width: 100%;
  }
  .baskettext h3{
    padding-right: 5%;
    font-size: 10px;
  }
  .basketsmalldiv .empt{
    width: 100%;
    font-size: 10px;
  }
  .totalprc{
    font-size: 10px;
    height: 40px;
  }
  .sbtotal{
    font-size: 10px;
  }
  .imgsml{
    width: 40%;
  }
  .basketimg{
    width: 100%;
    height: 50px;
    margin: 2px;
  }
  .imgsml img{
    width: 100%;
  }
  .baskettext{
    width: 60%;
    height: 40px;
    line-height: 15px;
    padding-left: 5%;
  }
  .baskettext h3{
    font-size: 15px;
  }
  .baskettext p{
    font-size: 10px;
    padding-left: 5%;
  }
  .btn-count{
    width: 80%;
    height: 13px;
    margin-left: 5px;
  }
  .btn-count button{
    width: 22%;
    height: 13px;
    font-size: 10px;
  }

}