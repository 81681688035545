.products002{
    width: 100%;
    height: auto;
    display: flex;
    /* background-image: url("patels.jpg"); */
  }
  .product-inspiration-2{
    width:50%;
    height: auto;
  }
  .product-inspiration-2{
    width: 50%;
    height: auto;
    padding-bottom: 3%;
  }
  .product-inspiration-2 p{
    color:#221C1C;
    font-size: 16px;
    line-height: 25px;
    width: 90%;
    padding-left: 20%;
    padding-top: 2%;
    font-family: "Tahoma", Sans-serif;
  }
  .product-inspiration-2 h2{
    font-size: 32px;
    color: purple;
    padding-top: 20%;
    padding-left: 20%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
  }
  .product-inspiration-2 hr{
    margin-top: 20px;
    width: 10%;
    margin-left: 20%;
    font-weight: bolder;
  }
  .product-inspiration-2 img{
    width: 55%;
    height: 520px;
    margin-top: 8%;
    margin-left: 8%;
  }
  .product-inspiration-2 span{
    color: #1A1A1A;
  }
  @media(max-width:768px){
    .products002{
      width: 100%;
    }
    .product-inspiration-2 h2{
      font-size: 20px;
      padding-top: 5%;
      padding-left: 5%;
    }
    .product-inspiration-2 p{
      font-size: 8px;
      line-height: 10px;
      width: 100%;
      padding-left: 5%;
      padding-top: 0%;
    }
    .product-inspiration-2 hr{
      margin-top: 5px;
      margin-left: 6%;
    }
    .product-inspiration-2 img{
      width: 90%;
      height: 220px;
    }
    
  }