.fragrance{
    width: 100%;
    height: 380px;
    display: flex;
    margin-top: 100px;
    }
    .div1-frag{
    width: 50%;
    height: 100%;
    text-align: center;
    font-size: 23px;
    padding-top: 1%;
    padding-left: 15%;
    }
    .div1-frag h2{
    width: 70%;
    margin: auto;
    font-size: 50px;
    font-family: 'Dancing Script', cursive;
    line-height: 43px;
    font-weight: bold;
    color:  #221C1C;
    }
    .div1-frag p{
    width: 90%;
    margin: auto;
    padding-top: 4%;
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 600;
    color:  #221C1C;
    }
    .div2-frag{
    width: 50%;
    height: 100%;
    }
    .div2-frag img{
    width: 66%;
    height: 100%;
    margin-left: 5%;
    }
    @media(max-width:768px){
        .fragrance{
            width: 100%;
            margin-top: 0%;
            height: auto;
        }
        .div1-frag{
            padding: 0%;
            margin-top: 10%;
        }
        .div1-frag h2{
            font-size: 20px;
            line-height: 25px;
        }
        .div1-frag p{
            font-size: 10px;
        }
        .div2-frag img{
            height: 150px;
            margin-left: 10%;
            margin-top: 10%;
        }

    }